<div class="main-content resource-detail body-bg custom-management-wrapper">
  <div class="relative">
    <div class="management-header-wrapper p-4">
      <h4 class="mat-headline-5 strong text mb-1">Resource Details</h4>
      <sft-button
        [label]="'Detail view of resource'"
        [color]="'primary'"
        className="grey-text-btn textXS p-0"
        [variant]="'default'"
        [disable]="false"
        [withIcon]="true"
        [icon]="'keyboard_backspace'"
        [disableRipple]="false"
        [size]="'small'"
        (click)="goBack()"
      ></sft-button>
    </div>
    <div class="img-wrapper mb-4 px-4">
      <div class="backgound-img">
        <img src="/images/background.png" alt="background-img" />
      </div>
      <div class="d-flex relative img-content">
        <div class="mr-2 absolute profile-img">
          <ng-container *ngIf="element">
            <ng-container *ngIf="element.logo; else defaultLogo">
              <img
                src="/images/profileFrame.png"
                alt="profile"
                style="border-radius: 50%"
              />
            </ng-container>
            <ng-template #defaultLogo>
              <div
                style="
                  background-color: #005F8E;
                  color: #fff;
                  display: flex;
                  height: 100%;
                  justify-content: center;
                  align-items: center;
                  font-size: 60px;
                "
              >
                {{ getDefaultPhotoText(element.name) }}
              </div>
            </ng-template>
          </ng-container>
        </div>

        <div class="profile-info">
          <span class="mat-subtitle-1 text fw-600 mb-1 d-block">{{
            name
          }}</span>
          <div>
            <span class="textXS grey-text mr-9">{{ role }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex body-bg px-4 pb-4">
      <div class="left-content mr-4">
        <div class="d-flex justify-between align-center">
          <h3 class="mat-subtitle-2 text fw-500 mb-2">About</h3>
        </div>
        <table class="table-wrapper mb-6">
          <tbody>
            <tr class="mb-2">
              <th class="identity black-text textS mr-2 d-block">Full Name:</th>
              <td class="identity-info grey-text textS" [title]="name">
                {{ name | truncate : 10 || "--" }}
              </td>
            </tr>
            <tr class="mb-2">
              <th class="identity black-text textS mr-2 d-block">Role:</th>
              <td class="identity-info grey-text textS">
                {{ role }}
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <h3 class="mat-subtitle-2 text fw-500 mb-2">Contacts</h3>
          <table class="table-wrapper">
            <tbody>
              <tr class="mb-2">
                <th class="identity black-text textS mr-2 d-block">Contact:</th>
                <td class="identity-info grey-text textS">
                  {{ phone || "--" }}
                </td>
              </tr>
              <tr class="mb-2">
                <th class="identity black-text textS mr-2 d-block">Email:</th>
                <td class="identity-info grey-text textS" title="{{ email }}">
                  {{ email | truncateEmail : 25 }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="table-info w-100">
        <app-loader *ngIf="loading"></app-loader>
        <ng-container *ngIf="!loading">
          <div class="listing-content">
            <h3 class="mat-subtitle-2 text fw-500 mb-2">Projects</h3>
            <ng-container
              *ngIf="projectList && projectList.length > 0; else emptyList"
            >
              <table
                mat-table
                [dataSource]="projectList"
                class="custom-action-table shadow-none"
                matSort
                (matSortChange)="onSort($event)"
              >
                <ng-container matColumnDef="name">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header="projectName"
                  >
                    Name
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element"
                    title="{{ element.name || '--' }}"
                  >
                    <span class="grey-text project-name-col table-ellipse">{{
                      element.name | truncate : 10 || "--"
                    }}</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="role">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header="centerName"
                  >
                    Project Cost
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element"
                    class="grey-text project-cost"
                    title=" {{ element?.center_name || '--' }}"
                  >
                    {{
                      element?.center_name
                        ? (element.center_name | truncate : 10)
                        : "--"
                    }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="account">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="Status-info"
                    mat-sort-header="status"
                  >
                    Status
                  </th>
                  <td mat-cell *matCellDef="let element" class="grey-text">
                    <div
                      class="account-status justify-center d-flex align-center textXS fw-500"
                    >
                      <div
                        *ngIf="isStatusOngoing(element.status)"
                        class="success-text success-light-bg w-fit px-2 table-chip"
                      >
                        {{ getFormattedStatus(element.status) }}
                      </div>

                      <div
                        *ngIf="isStatusOnHold(element.status)"
                        class="warning-light-bg warning-text w-fit px-2 table-chip"
                      >
                        {{ getFormattedStatus(element.status) }}
                      </div>

                      <div
                        *ngIf="isStatusCompleted(element.status)"
                        class="info-text info-light-bg w-fit px-2 table-chip"
                      >
                        {{ getFormattedStatus(element.status) }}
                      </div>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="rate">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header="costModal"
                  >
                    Project Cost Model
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element"
                    class="grey-text project-cost model"
                    title="{{
                      element.cost_modal === 'hourly'
                        ? 'Hourly/Per day Billing'
                        : element.cost_modal === 'fixed'
                        ? 'Fixed Cost Project'
                        : element.cost_modal === 'daily'
                        ? 'Daily Billing'
                        : '--'
                    }}"
                  >
                    {{
                      element.cost_modal === "hourly"
                        ? "Hourly/Per day Billing"
                        : element.cost_modal === "fixed"
                        ? "Fixed Cost Project"
                        : element.cost_modal === "daily"
                        ? "Daily Billing"
                        : "--"
                    }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="start">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    class="date-info"
                    mat-sort-header="startDate"
                  >
                    Joined On
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element"
                    class="grey-text date-col"
                  >
                    {{
                      element.start_date
                        ? (element.start_date | date : "d MMM y")
                        : "--"
                    }}
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  class="action-table-header-data"
                  *matHeaderRowDef="displayedColumns"
                ></tr>
                <tr
                  mat-row
                  class="action-table-body-data"
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
            </ng-container>
            <ng-template #emptyList>
              <div
                class="required-star"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: calc(100vh - 100px);
                  font-size: larger;
                "
              >
                <div>No projects assigned yet.</div>
              </div>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
