<div class="main-content body-bg listing-wrapper custom-management-wrapper">
  <div class="p-6">
    <div class="d-flex justify-between align-center mb-6">
      <div>
        <h4 class="mat-headline-5 strong text" style="margin-bottom: 0">
          Team Management
        </h4>
        <p class="medium mat-body-1 text textXS">Manage Your team</p>
      </div>
      <div class="d-flex mb-4">
        <mat-form-field appearance="outline" class="search-input mr-3" [formGroup]="form">
          <input type="text" matInput placeholder="Search" formControlName="searchQuery" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <div class="header-info-wrapper">
          <button mat-button class="outline-button" (click)="openFilterDrawer()">
            <img src="/images/filter.png" alt="" class="mr-2" />
            <div class="d-flex align-center">
            <span class="mr-2">Filter</span>
            <div *ngIf="isFilterApplied" class="active-circle"></div>
            </div>
          </button>
        </div>
      </div>
    </div>

    <div class="body-bg dashboard-wrapper">
      <div class="body-bg table-container overflow-unset">
        <app-loader *ngIf="loading"></app-loader>
        <div *ngIf="!loading && teamManagementList.length === 0" class="required-star" style="
            display: flex;
            justify-content: center;
            align-items: center;
            height: calc(100vh - 200px);
            font-size: 20px;
          ">
          No teams found!
        </div>
        <div class="overflow-auto cost-listing-wrapper" *ngIf="!loading && teamManagementList.length > 0">
          <table mat-table [dataSource]="teamManagementList" class="custom-action-table" style="cursor: pointer" matSort
            (matSortChange)="onTeamManagementListSort($event)">
            <ng-container matColumnDef="resourceName">
              <th mat-header-cell *matHeaderCellDef class="name" mat-sort-header="resourceName">
                Resource Name
              </th>
              <td mat-cell *matCellDef="let element" [routerLink]="getResourceDetails(element)"
                title="{{ element?.name || '--' }}">
                <div class="d-flex align-center">
                  <span class="grey-text table-ellipse resource-col">{{
                    element?.name || "--"
                    }}</span>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="role">
              <th mat-header-cell *matHeaderCellDef class="role" mat-sort-header="role">
                Role
              </th>
              <td mat-cell *matCellDef="let element" class="grey-text table-ellipse resource-col"
                [routerLink]="getResourceDetails(element)" title="{{ element?.role || '--' }}">
                {{ element?.role || "--" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="costCenter">
              <th mat-header-cell *matHeaderCellDef class="role" mat-sort-header="centerName">
                Cost Center Name
              </th>
              <td mat-cell *matCellDef="let element" class="grey-text cost-center-col table-ellipse resource-col"
                [routerLink]="getResourceDetails(element)" title="{{ element?.center_name || '--' }}">
                {{ element?.center_name ? element.center_name : "--" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="projectName">
              <th mat-header-cell *matHeaderCellDef class="role" mat-sort-header="projectName">
                Project Name
              </th>
              <td mat-cell *matCellDef="let element" class="grey-text resource-col"
                [routerLink]="getResourceDetails(element)" [title]="element?.projectName || '--'">
                <span *ngIf="element?.projectName; else noProjectName" class="project-col">
                  {{
                  element.projectName.length > 10
                  ? element.projectName || "--"
                  : element.projectName
                  }}
                </span>
                <ng-template #noProjectName> -- </ng-template>
              </td>
            </ng-container>
            <ng-container matColumnDef="startDate">
              <th mat-header-cell *matHeaderCellDef class="date" mat-sort-header="startDate">
                Start Date
              </th>
              <td mat-cell *matCellDef="let element" class="grey-text" [routerLink]="getResourceDetails(element)">
                {{ element?.start_date | date : "dd MMM, yyyy" || "--" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef class="action">Action</th>
              <td mat-cell *matCellDef="let element" class="text-center">
                <mat-icon [matMenuTriggerFor]="menuEdit"> more_vert </mat-icon>
                <mat-menu #menuEdit="matMenu" class="action-info">
                  <button mat-menu-item [routerLink]="getResourceDetails(element)">
                    View Details
                  </button>
                </mat-menu>
              </td>
            </ng-container>
            <tr mat-header-row class="action-table-header-data" *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row class="action-table-body-data" *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
        <mat-paginator *ngIf="!loading && teamManagementList.length > 0"
          class="action-table-pagination w-full management fixed bottom-0" [length]="totalRecords" [pageSize]="limit"
          [pageIndex]="pageNumber" [pageSizeOptions]="[25, 50, 75, 100]" (page)="onPageChange($event)"
          aria-label="Select page">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
<app-team-management-dialog-filter #filter
  (filtersApplied)="handleFilterApplied($event)"></app-team-management-dialog-filter>