import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'src/app/admin-portal/core/services/api.service';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class TeamManagementService {
  baseUrl = environment.apiUrl;
  searchQuery = new BehaviorSubject<any>('');
  constructor(private apiservice: ApiService) {}

  emitSearchQuery(arg: String) {
    this.searchQuery.next(arg);
  }

  getTeamManagementList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: '' | 'ASC' | 'DESC' = '',
    accountID?: string,
    cost_centerID?: string,
    projectId?: string
  ): Observable<any> {
    let path = `${this.baseUrl}/customer/team-managemant/list?page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;

    if (accountID) {
      path += `&accountId=${accountID}`;
    }

    if (cost_centerID) {
      path += `&cost_centerID=${cost_centerID}`;
    }

    if (projectId) {
      path += `&projectId=${projectId}`;
    }
    return this.apiservice.get(path);
  }
}
