import { Component, EventEmitter, Output } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import { Observable, tap } from 'rxjs';
import { CostCenterService } from 'src/app/admin-portal/modules/cost-center/services/cost-center.service';
import { ProjectService } from 'src/app/admin-portal/modules/project/services/project.service';

@Component({
  selector: 'app-team-management-dialog-filter',
  templateUrl: './filter-team-management-sidebar.component.html',
})
export class FilterTeamManagementSidebarComponent {
  side!: MatDrawerMode;
  isOpen: boolean = false;
  accountID: string = '';
  items = ['Cost Center', 'Project Name'];
  expandedIndex = 0;
  costCenters: any[] = [];
  projects: any[] = [];
  selectedCostCenters: string[] = [];
  selectedProjects: string[] = [];

  selectedCostCentersMap: { [key: string]: boolean } = {};
  selectedProjectsMap: { [key: string]: boolean } = {};

  @Output() filtersApplied = new EventEmitter<any>();

  constructor(
    private costCenterService: CostCenterService,
    private projectService: ProjectService
  ) {}

  ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.accountID = user?.associatedAccount?.accountID;

    this.loadCostCenters().subscribe();
    this.loadProjects().subscribe();
  }

  loadCostCenters(): Observable<any> {
    const MAX_LIMIT = 9999;
    return this.costCenterService
      .getCostListCustomer(0, MAX_LIMIT, '', 'createdAt', 'ASC', this.accountID)
      .pipe(
        tap((response: any) => {
          this.costCenters = response.data.records;
        })
      );
  }

  loadProjects(): Observable<any> {
    const MAX_LIMIT = 9999;
    return this.projectService
      .getProjectListCustomer(
        0,
        MAX_LIMIT,
        '',
        'createdAt',
        'ASC',
        '',
        this.accountID
      )
      .pipe(
        tap((response: any) => {
          this.projects = response.data.records;
        })
      );
  }

  hasSelectedFilters(): boolean {
    return this.selectedCostCenters.length > 0 || this.selectedProjects.length > 0;
  }

  applyFilters(): void {
    if (this.hasSelectedFilters()) {
      this.filtersApplied.emit({
        selectedCostCenters: this.selectedCostCenters,
        selectedProjects: this.selectedProjects,
      });
      this.isOpen = false;
    }
  }

  clearFilters(): void {
    this.selectedCostCenters = [];
    this.selectedProjects = [];
    this.selectedCostCentersMap = {};
    this.selectedProjectsMap = {};
    this.filtersApplied.emit({});
  }

  onCostCenterChange(event: any, center: any): void {
    if (event.checked) {
      this.selectedCostCenters.push(center.cc_cost_centerID);
    } else {
      const index = this.selectedCostCenters.indexOf(center.cc_cost_centerID);
      if (index > -1) {
        this.selectedCostCenters.splice(index, 1);
      }
    }
  }

  onProjectChange(event: any, project: any): void {
    if (event.checked) {
      this.selectedProjects.push(project.projectId);
    } else {
      const index = this.selectedProjects.indexOf(project.projectId);
      if (index > -1) {
        this.selectedProjects.splice(index, 1);
      }
    }
  }

  toggleCostCenterCheckbox(event: any, center: any) {
    const checkbox = event.target.querySelector('mat-checkbox input');
    if (checkbox) {
      checkbox.click();
    }
  }

  toggleProjectCheckbox(event: any, project: any) {
    const checkbox = event.target.querySelector('mat-checkbox input');
    if (checkbox) {
      checkbox.click();
    }
  }
}
