import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TeamManagementService } from '../../services/team-management.service';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { debounceTime, takeUntil } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { FilterTeamManagementSidebarComponent } from './filter-sidebar/filter-team-management-sidebar.component';

export interface PeriodicElement {
  resourceName: string;
  role: string;
  costCenter: string;
  projectName: string;
  startDate: string;
  action: string;
}

@Component({
  selector: 'app-team-management',
  templateUrl: './team-management.component.html',
})
export class TeamManagementComponent {
  pageNumber: number = 0;
  limit: number = 25;
  search: string = '';
  sortBy: 'jobTitle' | 'modifiedAt' = 'modifiedAt';
  orderBy: 'ASC' | 'DESC' = 'DESC';
  form!: FormGroup;
  totalRecords: number = 0;
  lastSortBy: string = '';
  accountID: string = '';
  loading: boolean = false;
  teamManagementList: any[] = [];
  projects: any[] = [];
  costCenters: any[] = [];
  element: any;
  isFilterApplied: boolean = false;

  constructor(
    private formbuilder: FormBuilder,
    private teamManagementService: TeamManagementService,
    private globalService: GlobalService
  ) {}

  displayedColumns: string[] = [
    'resourceName',
    'role',
    'costCenter',
    'projectName',
    'startDate',
    'action',
  ];
  dataSource = new MatTableDataSource<PeriodicElement>([]);

  @ViewChild('filter') filterComponent!: FilterTeamManagementSidebarComponent;

  openFilterDrawer(): void {
    if (this.filterComponent) {
      this.filterComponent.isOpen = !this.filterComponent.isOpen;
    } else {
    }
  }

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.accountID = user?.associatedAccount?.accountID;

    this.orderBy = 'DESC';
    this.getTeamManagementList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy
    );
    this.form = this.formbuilder.group({
      searchQuery: [''],
      costCenter: [''],
    });
    this.searchTeamManagement();
  }

  getTeamManagementList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC'
  ): void {
    this.loading = true;
    this.teamManagementService
      .getTeamManagementList(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        this.accountID
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.teamManagementList = data?.data?.records;
          this.totalRecords = data?.data?.count || 0;
          this.loading = false;
          this.teamManagementList.sort((a, b) => {
            return (
              new Date(b.modifiedAt).getTime() -
              new Date(a.modifiedAt).getTime()
            );
          });
        },
        error: (error: any) => {
          console.error('Error in Team Management List request:', error);
          this.loading = false;
        },
      });
  }

  getTeamManagementListByCostCenter(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedCostCenters: string[]
  ): void {
    this.loading = true;
    const costCenterID = selectedCostCenters.join('&cost_centerID=');
    this.teamManagementService
      .getTeamManagementList(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        this.accountID,
        costCenterID
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response) => {
          this.teamManagementList = response.data.records;
          this.loading = false;
        },
        error: (error) => {
          console.error('Error in Team Management List request:', error);
          this.loading = false;
        },
      });
  }

  getTeamManagementListByProject(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedProjects: string[]
  ): void {
    this.loading = true;
    const projectId = selectedProjects.join('&projectId=');
    this.teamManagementService
      .getTeamManagementList(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        this.accountID,
        undefined,
        projectId
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response) => {
          this.teamManagementList = response.data.records;
          this.loading = false;
        },
        error: (error) => {
          console.error('Error in  Team Management List request:', error);
          this.loading = false;
        },
      });
  }

  getTeamManagementListByAllFilters(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    selectedCostCenters: string[],
    selectedProjects: string[]
  ): void {
    this.loading = true;
    const costCenterID = selectedCostCenters.join('&cost_centerID=');
    const projectId = selectedProjects.join('&projectId=');
    this.teamManagementService
      .getTeamManagementList(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        this.accountID,
        projectId,
        costCenterID
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response) => {
          this.teamManagementList = response.data.records;
          this.loading = false;
        },
        error: (error) => {
          console.error('Error in Team Management List request:', error);
          this.loading = false;
        },
      });
  }

  handleFilterApplied(filters: any): void {
    const selectedCostCenters = filters.selectedCostCenters || [];
    const selectedProjects = filters.selectedProjects || [];
    if (selectedCostCenters.length > 0 && selectedProjects.length > 0) {
      this.getTeamManagementListByAllFilters(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        selectedProjects,
        selectedCostCenters
      );
    } else if (selectedProjects.length > 0) {
      this.getTeamManagementListByProject(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        selectedProjects
      );
    } else if (selectedCostCenters.length > 0) {
      this.getTeamManagementListByCostCenter(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        selectedCostCenters
      );
    } else {
      this.getTeamManagementList(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy
      );
    }
    this.isFilterApplied = filters && Object.keys(filters).length > 0;
  }

  onTeamManagementListSort(event: Sort): void {
    const sortBy = event.active;
    const orderBy = event.direction.toUpperCase() as 'ASC' | 'DESC';
    const validOrderBy = orderBy || 'ASC';

    if (sortBy !== this.lastSortBy) {
      this.pageNumber = 0;
    }
    this.lastSortBy = sortBy;

    this.teamManagementService
      .getTeamManagementList(
        this.pageNumber,
        this.limit,
        this.search,
        sortBy,
        validOrderBy,
        this.accountID
      )
      .subscribe({
        next: (data: any) => {
          this.teamManagementList = data?.data?.records || [];
          this.dataSource.data = this.teamManagementList;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in Team Management List request:', error);
        },
      });
  }

  onPageChange(event: PageEvent): void {
    this.pageNumber = event.pageIndex;
    this.limit = event.pageSize;
    this.getTeamManagementList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy
    );
  }

  getResourceDetails(element: any) {
    if (element.core_teamID) {
      return ['/customer/team-management/view/', element.core_teamID];
    } else {
      return ['/customer/team-management/view/', element.projectResourceID];
    }
  }

  searchTeamManagement(): void {
    this.form
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        this.getTeamManagementList(
          this.pageNumber,
          this.limit,
          this.search,
          this.sortBy,
          this.orderBy
        );
      });
  }

  setElement(selectedElement: any): void {
    this.element = selectedElement;
  }
}
