import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { ResourceService } from 'src/app/admin-portal/modules/resource/services/resource.service';
import { takeUntil } from 'rxjs';
import { ProjectService } from 'src/app/admin-portal/modules/project/services/project.service';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export interface PeriodicElement {
  name: string;
  role: string;
  start: string;
  account: string;
  rate: string;
}

@Component({
  selector: 'app-accout-action-detail',
  templateUrl: './team-management-detail.component.html',
})
export class TeamManagementDetailComponent implements OnInit {
  id!: string;
  resourceID!: string;
  name!: any;
  role!: any;
  pricing!: any;
  startDate!: string;
  isSidebarOpen = false;
  isDrawerOpen = false;
  isActive = false;
  totalRecords!: number;
  element: any;
  projectList: any[] = [];
  pageNumber: number = 0;
  limit: number = 25;
  search: string = '';
  sortBy: 'projectName' | 'modifiedAt' = 'modifiedAt';
  lastSortBy: string = '';
  orderBy: 'ASC' | 'DESC' = 'ASC';
  resource: any[] = [];
  loading: boolean = false;
  email: string = '';
  phone: string = '';
  accountID: string = '';

  constructor(
    private resourceService: ResourceService,
    private projectService: ProjectService,
    private router: Router,
    private route: ActivatedRoute,
    private globalService: GlobalService
  ) {}

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.accountID = user?.associatedAccount?.accountID;

    this.id = this.route.snapshot.params['id'];
    this.fetchResourceDetails().then(() => {
      this.getResourceProject(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        this.resourceID
      );
    });
  }

  async fetchResourceDetails(): Promise<void> {
    return new Promise((resolve, reject) => {
      const element: { core_teamID?: string; projectResourceID?: string } = {
        core_teamID: this.id,
      };
      if (element.core_teamID) {
        this.resourceService
          .getResourceDetails(element.core_teamID, 'core_teamID')
          .subscribe({
            next: (res) => {
              const resourceData = res.data;
              this.name = resourceData?.name;
              this.email = resourceData?.email;
              this.phone = resourceData?.phone;
              this.role = resourceData?.role;
              this.pricing = resourceData?.pricing;
              this.startDate = resourceData?.startDate;
              this.element = resourceData;
              this.resourceID = resourceData?.resourceID;
              resolve();
            },
            error: (err) => {
              reject(err);
            },
          });
      } else if (element.projectResourceID) {
        this.resourceService
          .getResourceDetails(element.projectResourceID, 'projectResourceID')
          .subscribe({
            next: (res) => {
              const resourceData = res.data;
              this.name = resourceData?.name;
              this.email = resourceData?.email;
              this.phone = resourceData?.phone;
              this.role = resourceData?.role;
              this.pricing = resourceData?.pricing;
              this.startDate = resourceData?.startDate;
              this.element = resourceData;
              this.resourceID = resourceData?.resourceID;
              resolve();
            },
            error: (err) => {
              reject(err);
            },
          });
      } else {
        console.error('No valid ID found for the resource.');
        reject('No valid ID found for the resource.');
      }
    });
  }

  isStatusOngoing(status: string): boolean {
    return (
      !!status &&
      (status.toLowerCase() === 'active' || status.toLowerCase() === 'ongoing')
    );
  }

  isStatusOnHold(status: string): boolean {
    return !!status && status.toLowerCase() === 'hold';
  }

  isStatusCompleted(status: string): boolean {
    return !!status && status.toLowerCase() === 'completed';
  }

  getFormattedStatus(status: string): string {
    switch (status.toLowerCase()) {
      case 'active':
        return 'Ongoing';
      case 'hold':
        return 'On hold';
      case 'completed':
        return 'Completed';
      default:
        return '';
    }
  }

  onSort(event: Sort): void {
    const sortBy = event.active;
    const orderBy = event.direction.toUpperCase() as 'ASC' | 'DESC';
    const validOrderBy = orderBy || 'ASC';

    if (sortBy !== this.lastSortBy) {
      this.pageNumber = 0;
    }
    this.lastSortBy = sortBy;

    this.projectService
      .getProjectByResourceId(
        this.pageNumber,
        this.limit,
        this.search,
        sortBy,
        validOrderBy,
        this.resourceID
      )
      .subscribe({
        next: (data: any) => {
          this.resource = data?.data?.records || [];
          this.dataSource.data = this.resource;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in Project List request:', error);
        },
      });
  }

  getResourceProject(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: string,
    resourceId: string
  ): void {
    this.loading = true;
    this.projectService
      .getCustomerProjectByResourceId(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        resourceId,
        this.accountID
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response: any) => {
          this.projectList = response?.data?.records;
          this.totalRecords = response?.data?.count || 0;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in fetching resource list:', error);
          this.loading = false;
        },
      });
  }

  getDefaultPhotoText(resourceName: string): string {
    if (!resourceName) return '';

    const firstChar = resourceName.charAt(0);
    const indexOfFirstSpace = resourceName.indexOf(' ');
    let secondChar = '';
    if (
      indexOfFirstSpace !== -1 &&
      indexOfFirstSpace < resourceName.length - 1
    ) {
      secondChar = resourceName.charAt(indexOfFirstSpace + 1);
    }

    return `${firstChar}${secondChar}`.toUpperCase();
  }

  displayedColumns: string[] = ['name', 'role', 'rate', 'account', 'start'];
  dataSource = new MatTableDataSource<PeriodicElement>([]);

  goBack() {
    this.router.navigateByUrl('/customer/team-management');
  }
}
