import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarModule } from 'src/app/admin-portal/core/layouts/sidebar/sidebar.module';
import { CustomerLayoutModule } from '../../core/layout/layout.module';
import { SftButtonModule } from 'src/stories/components/button/button.module';
import { TeamManagementComponent } from './components/team-management-listing/team-management.component';
import { CustomerTeamManagementRoutingModule } from './team-management-routing.module';
import { AngularMaterialModule } from 'src/shared/modules/angular-material/angular-material.module';
import { LoaderComponent } from 'src/shared/components/loader/loader.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TeamManagementDetailComponent } from './components/team-management-detail/team-management-detail.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/shared/modules/shared/shared.module';
import { FilterTeamManagementSidebarComponent } from './components/team-management-listing/filter-sidebar/filter-team-management-sidebar.component';

@NgModule({
  declarations: [
    TeamManagementComponent,
    TeamManagementDetailComponent,
    FilterTeamManagementSidebarComponent,
  ],
  imports: [
    CustomerLayoutModule,
    LoaderComponent,
    CommonModule,
    SidebarModule,
    SftButtonModule,
    CustomerTeamManagementRoutingModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
  ],
})
export class CustomerTeamManagementModule {}
